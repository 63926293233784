import { convertBody } from './convertBody';

export const fetchResponseToHttpClientResponse = async <TResponse>(
  response: Response
) => {
  return {
    body: (await convertBody(response)) as TResponse,
    status: response.status,
  };
};
