import { consoleReporter } from './consoleReporter';
import {
  type LoggerContext,
  type LoggerMessage,
  type LoggerMetadata,
  type LoggerReport,
  type LoggerReporter,
  type LoggerTransformation,
} from './types';

type LoggerConfig = {
  metadata?: LoggerMetadata;
  onReporterError?: (error: unknown) => void;
  onTransformationError?: (error: unknown) => void;
  reporters?: LoggerReporter[];
  transformations?: LoggerTransformation[];
};

export class Logger {
  private context: LoggerContext = {};

  private readonly metadata: LoggerMetadata;

  private readonly onReporterError: (error: unknown) => void;

  private readonly onTransformationError: (error: unknown) => void;

  private readonly reporters: LoggerReporter[];

  private readonly transformations: LoggerTransformation[];

  constructor(config?: LoggerConfig) {
    this.metadata = config?.metadata ?? {};
    this.onReporterError = config?.onReporterError ?? console.error;
    this.onTransformationError = config?.onTransformationError ?? console.error;
    this.transformations = config?.transformations ?? [];
    this.reporters =
      config?.reporters && config?.reporters?.length > 0
        ? config.reporters
        : [consoleReporter];
  }

  private log(report: Omit<LoggerReport, 'metadata'>): void {
    const transformedReport = this.transformReport({
      ...report,
      context: { ...this.context, ...report.context },
      metadata: this.metadata,
    });

    for (const reporter of this.reporters) {
      try {
        if (
          !reporter.reportableSeverities ||
          (reporter.reportableSeverities &&
            reporter.reportableSeverities.includes(transformedReport.severity))
        ) {
          reporter.process(transformedReport);
        }
      } catch (error) {
        this.onReporterError(error);
      }
    }
  }

  private transformReport(initialReport: LoggerReport): LoggerReport {
    return this.transformations.reduce((accReport, transform) => {
      try {
        return transform(accReport);
      } catch (error) {
        this.onTransformationError(error);
        return accReport;
      }
    }, structuredClone(initialReport));
  }

  public error(message: LoggerMessage, context?: LoggerContext): void {
    this.log({ context, message, severity: 'error' });
  }

  public info(message: LoggerMessage, context?: LoggerContext): void {
    this.log({ context, message, severity: 'info' });
  }

  public setContext(
    context: ((ctx: LoggerContext) => LoggerContext) | LoggerContext
  ) {
    this.context =
      typeof context === 'function' ? context(this.context) : context;
  }

  public warn(message: LoggerMessage, context?: LoggerContext): void {
    this.log({ context, message, severity: 'warn' });
  }
}
