/* eslint-disable perfectionist/sort-objects */
/* eslint-disable perfectionist/sort-object-types */
export type BuildUrlParams = {
  baseUrl?: string;
  path?: string;
  params?: Record<string, boolean | string | undefined>;
};

export const buildUrl = ({ baseUrl, path, params }: BuildUrlParams) => {
  return `${baseUrl}${path}${
    params
      ? `?${Object.entries(params)
          .filter(([, value]) => value !== undefined)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')}`
      : ''
  }`;
};
