import { UserBrowser } from '@soluto-private/mx-user-browser';

import {
  type HttpClientResponse,
  HttpHeader,
} from '@service-initiation/shared-clients-http';
import { type CreateCallbackClientRequest } from '@service-initiation/shared-clients-widget-services';
import { queryClient } from '@service-initiation/shared-utils-query-client';
import { logger } from '@service-initiation/widget-clients-logger';
import { widgetServicesClient } from '@service-initiation/widget-clients-widget-services';

export const createCallback = async ({
  appointmentTime,
  client,
  data,
  mdn,
}: CreateCallbackClientRequest): Promise<HttpClientResponse<string>> => {
  const res = queryClient.fetchQuery({
    queryFn: async () => {
      const res = await widgetServicesClient.createCallbackRequest(
        {
          appointmentTime,
          client,
          data,
          mdn,
        },
        {
          headers: {
            [HttpHeader.AsurionCorrelationId]: UserBrowser.SessionId,
          },
        }
      );
      if (res.status !== 200) {
        logger.error('An unexpected error occurred. Please try again.', {
          res,
        });
        throw new Error(res.body);
      }
      return res;
    },
    queryKey: ['createCallback'],
    retry: 2,
    staleTime: Infinity,
  });
  return res;
};
