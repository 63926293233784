import { useCallback, useState } from 'react';
import { type ObjectSchema } from 'yup';

import {
  type FormErrorFields,
  type FormSideEffects,
  type FormSpecialFormatters,
} from './types';

export const useFormValidation = <Form extends Record<string, unknown>>({
  form,
  schema,
  sideEffects,
  specialFormatters,
}: {
  form: Form;
  schema: () => ObjectSchema<Form>;
  sideEffects?: FormSideEffects<Form>;
  specialFormatters?: FormSpecialFormatters<Form>;
}) => {
  const [formState, setFormState] = useState<Form>({ ...form });

  const [errorFields, setErrorFields] = useState<
    Record<keyof Form, string | undefined>
  >(
    Object.keys(form).reduce(
      (acc, key) => ({ ...acc, [key]: undefined }),
      {} as FormErrorFields<Form>
    )
  );

  const validateForm = useCallback(() => {
    schema()
      .validate(formState, { abortEarly: false })
      .then(() => {
        setErrorFields((prevErrorFields) =>
          Object.keys(prevErrorFields).reduce(
            (acc, key) => ({ ...acc, [key]: undefined }),
            {} as FormErrorFields<Form>
          )
        );
      })
      .catch((error) => {
        setErrorFields((prevErrorFields) =>
          Object.keys(prevErrorFields).reduce(
            (acc, key) => ({
              ...acc,
              [key]:
                error.inner.find((err: { path: string }) => err.path === key)
                  ?.message ?? '',
            }),
            {} as FormErrorFields<Form>
          )
        );
      });
  }, [formState, schema]);

  const validateField = useCallback(
    ({ fieldName, fieldValue }: { fieldName: string; fieldValue: unknown }) => {
      const fieldData = { [fieldName]: fieldValue };

      schema()
        .validateAt(fieldName, fieldData)
        .then(() => {
          setErrorFields((prevErrorFields) => ({
            ...prevErrorFields,
            [fieldName]: undefined,
          }));
        })
        .catch((error) => {
          setErrorFields((prevErrorFields) => ({
            ...prevErrorFields,
            [fieldName]: error?.errors[0],
          }));
        });
    },
    [schema]
  );

  const handleChange = useCallback(
    ({
      target: { name, value },
    }: React.ChangeEvent<Partial<HTMLInputElement>>) => {
      if (name) {
        const key = name as keyof Form;
        const sideEffect = sideEffects?.[key];
        const formatter = specialFormatters?.[key];

        sideEffect && setFormState(sideEffect(formState) as Form);

        const finalValue = formatter?.(value) ?? value ?? '';
        setFormState((currentForm) => ({ ...currentForm, [key]: finalValue }));

        errorFields[name] &&
          validateField({ fieldName: name, fieldValue: value });
      }
    },
    [errorFields, formState, sideEffects, specialFormatters, validateField]
  );

  const handleBlur = useCallback(
    ({
      target: { name, value },
    }: React.BaseSyntheticEvent<Partial<HTMLInputElement>>) => {
      validateField({ fieldName: name, fieldValue: value });
    },
    [validateField]
  );

  const handleFormValidationAndSubmit = useCallback(
    (submitForm: (form: Form) => unknown) => {
      validateForm();
      if (schema().isValidSync(formState)) {
        submitForm(formState);
      }
    },
    [formState, schema, validateForm]
  );

  const resetForm = useCallback((formPartial?: Partial<Form>) => {
    setFormState((currentForm) => ({ ...currentForm, ...formPartial }));
  }, []);

  return {
    errorFields,
    formState,
    handleBlur,
    handleChange,
    handleFormValidationAndSubmit,
    resetForm,
    validateForm,
  };
};
