import styled from 'styled-components';

import {
  Icon as AuiIcon,
  Text,
  colors,
} from '@soluto-private/mx-asurion-ui-react';

const Icon = styled(AuiIcon)`
  margin-left: auto;
`;

const TextWrapper = styled.div`
  width: 100%;
`;

export const ExpandedHeaderTopicPanelContent = () => (
  <>
    <Icon fill={colors.white} size="large" src="chevron-down" title="asurion" />
    <TextWrapper>
      <Text as="h1" size={4}>
        Expert help trusted by millions worldwide
      </Text>
    </TextWrapper>
  </>
);
