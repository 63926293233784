import styled, { keyframes } from 'styled-components/macro';

import googleLogo from './google-logo.png';

const SplashScreenWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0px;
`;

const fadeInAnim = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const SplashWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 1;
`;

const SplashImage = styled.img`
  max-width: 125px;
  height: auto;
  width: 50.37px;
  height: 50.37px;
  max-width: 150px;
  display: flex;
  opacity: 0;
  animation: ${fadeInAnim} 0.7s ease forwards;
  animation-delay: 0.6s;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const SplashScreenBackground = styled.div`
  width: 100%;
  height: 100%;
`;

const SplashGraphic = () => {
  return (
    <SplashWrapper>
      <SplashImage alt="Google company logo" src={googleLogo} />
    </SplashWrapper>
  );
};

export function SplashScreen() {
  return (
    <SplashScreenWrapper aria-hidden="true">
      <SplashScreenBackground data-testid="splash_screen_background">
        <SplashGraphic />
      </SplashScreenBackground>
    </SplashScreenWrapper>
  );
}
