import {
  handleDownloadTranscript,
  useMessagesStore,
} from '@simplrsupport/headless-chat-web';
import { Suspense, lazy, useRef, useState } from 'react';

import { StepContentSkeleton } from '@service-initiation/shared-ui-step-content-skeleton';
import {
  AuiToken,
  useApplyAuiTokenClass,
} from '@service-initiation/shared-utils-hooks';
import { useDispatchSubPageAtomValue } from '@service-initiation/widget-data-access-storage';
import { useGetWidgetStatus } from '@service-initiation/widget-data-access-widget-services';
import { AnimatedPanelStack } from '@service-initiation/widget-features-animated-panel-stack';
import { Bumper } from '@service-initiation/widget-features-bumper';
import {
  SimplrChat,
  TopicHeader,
  useConversationStarted,
  useTerminateChat,
} from '@service-initiation/widget-features-simplr-chat';
import { SplashScreen } from '@service-initiation/widget-google-features-splash-screen';
import { ExitModal } from '@service-initiation/widget-google-ui-exit-modal';

import { HeaderContent } from './HeaderContent';
import './app.css';
import { googleTheme } from './googleTheme';
import { useSimplrChatActionListener } from './hooks';

const TopicsPanel = lazy(() =>
  import('@service-initiation/widget-google-features-topics-panel').then(
    (module) => ({ default: module.TopicsPanel })
  )
);

const MindfulPanel = lazy(() =>
  import('@service-initiation/widget-google-features-mindful-panel').then(
    (module) => ({ default: module.MindfulPanel })
  )
);

const topics = [
  {
    auiIcon: 'messages-and-chat/chat-bubbles-square',
    text: 'Transfer texts or WhatsApp messages',
  },
  {
    auiIcon: 'locations/location-map',
    text: 'Get a guided tour of my Pixel',
  },
  {
    auiIcon: 'camera-1',
    text: 'Get the most out of my new camera',
  },
  {
    auiIcon: 'wrench',
    text: 'Report wrong, broken, or missing device or accessory',
  },
  {
    auiIcon: 'user',
    text: 'Get help with an account issue',
  },
];

const App = () => {
  useApplyAuiTokenClass(document.body, AuiToken.PartnerLight);
  const dispatchSubPage = useDispatchSubPageAtomValue();
  const conversationStarted = useConversationStarted();
  const bumperRef = useRef<HTMLDivElement>(null);
  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const terminateConnection = useTerminateChat();
  useSimplrChatActionListener();

  const messageHistory = useMessagesStore((s) => s.messages);

  const { loading: mindfulSlotsLoading, value: mindfulEwtAndDates } =
    useGetWidgetStatus();

  const panel = dispatchSubPage
    ? 'mindful-panel'
    : conversationStarted
    ? 'main-conversation-panel'
    : 'starter-topics-panel';

  const exitModalProps = {
    containerRef: bumperRef,
    handleCloseModal: () => setIsExitModalOpen(false),
    handleLeaveConversation: () => {
      setIsExitModalOpen(false);
      terminateConnection();
    },
    handleSaveConversation: () => handleDownloadTranscript(messageHistory),
    isExitModalOpen,
  };
  return (
    <Bumper bumperRef={bumperRef} theme={googleTheme}>
      <Bumper.Header>
        <HeaderContent panel={panel} />
      </Bumper.Header>
      <Bumper.Body>
        <AnimatedPanelStack active={panel}>
          <AnimatedPanelStack.Panel id="starter-topics-panel">
            <Suspense fallback={null}>
              <TopicsPanel topics={topics} />
            </Suspense>
          </AnimatedPanelStack.Panel>
          <AnimatedPanelStack.Panel id="main-conversation-panel">
            <Suspense fallback={null}>
              <ExitModal {...exitModalProps} />
              <TopicHeader onBackClick={() => setIsExitModalOpen(true)} />
              <SimplrChat splashScreen={<SplashScreen />} />
            </Suspense>
          </AnimatedPanelStack.Panel>
          <AnimatedPanelStack.Panel id="mindful-panel">
            <Suspense fallback={<StepContentSkeleton />}>
              {!mindfulSlotsLoading &&
                mindfulEwtAndDates &&
                dispatchSubPage && (
                  <MindfulPanel
                    data={dispatchSubPage}
                    ewtAndDates={mindfulEwtAndDates}
                  />
                )}
            </Suspense>
          </AnimatedPanelStack.Panel>
        </AnimatedPanelStack>
      </Bumper.Body>
    </Bumper>
  );
};

export default App;
