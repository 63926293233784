import { UserBrowser } from '@soluto-private/mx-user-browser';

import { type LoggerTransformation } from '@service-initiation/shared-clients-logger';

export const withUserBrowserContext: LoggerTransformation = (report) => {
  return {
    ...report,
    context: {
      ...report.context,
      id: UserBrowser.Id,
      sessionId: UserBrowser.SessionId,
    },
  };
};
