import {
  Bumper,
  useBumperContext,
} from '@service-initiation/widget-features-bumper';

import googleLogo from '../assets/google-logo.png';
import { CollapsedHeaderContent } from './CollapsedHeaderContent';
import { ExpandedHeaderConversationPanelContent } from './ExpandedHeaderConversationPanelContent';
import { ExpandedHeaderTopicPanelContent } from './ExpandedHeaderTopicPanelContent';

type HeaderContentProps = {
  panel: 'main-conversation-panel' | 'mindful-panel' | 'starter-topics-panel';
};

export const HeaderContent = ({ panel }: HeaderContentProps) => {
  const { expanded } = useBumperContext();

  return (
    <>
      <Bumper.Image alt="Google" src={googleLogo} />
      {expanded ? (
        panel === 'starter-topics-panel' ? (
          <ExpandedHeaderTopicPanelContent />
        ) : (
          <ExpandedHeaderConversationPanelContent />
        )
      ) : (
        <CollapsedHeaderContent />
      )}
    </>
  );
};
