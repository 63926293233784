import {
  type MessagesStoreState,
  type TMessage,
} from '@simplrsupport/headless-chat-web';

export const determineLatestMessage = (
  state: MessagesStoreState,
  message: TMessage
) => {
  const mostRecentMessageId = state.messages[state.messages.length - 1].id;
  return message.id === mostRecentMessageId;
};
