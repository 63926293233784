import { setGlobalConfig } from '@simplrsupport/headless-chat-web';
import { StrictMode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useEffectOnce } from 'react-use';

import { GlobalStyles } from '@soluto-private/mx-asurion-ui-react';

import { GlobalVendorScrollbarResetRule } from '@service-initiation/shared-ui-scrollbar-reset';
import { WidgetAnalyticsProvider } from '@service-initiation/widget-data-access-analytics';
import {
  Popup,
  useSetPopupAtom,
} from '@service-initiation/widget-data-access-storage';

import App from './app/app';

declare global {
  interface Window {
    _fs_run_in_iframe: boolean;
  }
}

window['_fs_run_in_iframe'] = true;

const container = document.getElementById('root');

export const Main = () => {
  const isGlobalConfigSet = useRef(false);
  const setPopupAtom = useSetPopupAtom();
  useEffectOnce(() => {
    if (!isGlobalConfigSet.current) {
      isGlobalConfigSet.current = true;

      if (!process.env.NX_PUBLIC_SIMPLR_BASE_URL) {
        throw new Error('NX_PUBLIC_SIMPLR_BASE_URL is not set');
      }

      if (!process.env.NX_PUBLIC_SIMPLR_FLOW_SYSTEM_ID) {
        throw new Error('NX_PUBLIC_SIMPLR_FLOW_SYSTEM_ID is not set');
      }

      if (!process.env.NX_PUBLIC_SIMPLR_WIDGET_ID) {
        throw new Error('NX_PUBLIC_SIMPLR_WIDGET_ID is not set');
      }

      setGlobalConfig({
        asurionChat: {
          serverUrl: process.env.NX_PUBLIC_SIMPLR_BASE_URL,
        },
        customFlowSystemId: process.env.NX_PUBLIC_SIMPLR_FLOW_SYSTEM_ID,
        publicServerDomain: process.env.NX_PUBLIC_SIMPLR_BASE_URL,
        publicServerPrefix: '/chat-api',
        showStateDebugger: true,
        uiActionHandlers: {
          sendPopup: (params) => {
            if (params.name === 'session_timeout') {
              setPopupAtom(Popup.SESSION_TIMEOUT);
            }
          },
        },
        widgetId: process.env.NX_PUBLIC_SIMPLR_WIDGET_ID,
      });
    }
  });

  return (
    <StrictMode>
      <GlobalStyles />
      <GlobalVendorScrollbarResetRule />
      <WidgetAnalyticsProvider>
        <App />
      </WidgetAnalyticsProvider>
    </StrictMode>
  );
};

ReactDOM.render(<Main />, container);
