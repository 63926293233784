import {
  SimplrChatSDK,
  useMessagesStore,
} from '@simplrsupport/headless-chat-web';
import { lazy, memo, useState } from 'react';
import { useEffectOnce } from 'react-use';

import {
  Popup,
  usePopupAtomValue,
} from '@service-initiation/widget-data-access-storage';

import { SessionTimeout } from './components';
import { useConversationStarted } from './hooks';

const ConversationPanel = lazy(() =>
  import('./components/ConversationPanel').then((module) => ({
    default: module.ConversationPanel,
  }))
);

const SdkConnectionWrapper = ({
  children,
  setIsSplashDelayOver,
}: {
  children: JSX.Element;
  setIsSplashDelayOver: (value: boolean) => void;
}) => {
  useEffectOnce(() => {
    setIsSplashDelayOver(false);
    SimplrChatSDK.establishConnection();
    const timeoutCode = setTimeout(() => {
      setIsSplashDelayOver(true);
    }, 1100);

    return () => clearTimeout(timeoutCode);
  });

  return children;
};

const ConversationPanelWrapper = ({
  isSplashDelayOver,
  splashScreen,
}: {
  isSplashDelayOver: boolean;
  splashScreen: JSX.Element;
}) => {
  const messagesExist = useMessagesStore((s) => !!s.messages.length);
  return messagesExist && isSplashDelayOver ? (
    <ConversationPanel />
  ) : (
    splashScreen
  );
};

type SimplrChatProps = {
  splashScreen: JSX.Element;
};

export const SimplrChat = memo(({ splashScreen }: SimplrChatProps) => {
  const conversationStarted = useConversationStarted();
  const [isSplashDelayOver, setIsSplashDelayOver] = useState(false);
  const popup = usePopupAtomValue();

  return popup && popup === Popup.SESSION_TIMEOUT ? (
    <SessionTimeout />
  ) : conversationStarted ? (
    <SdkConnectionWrapper setIsSplashDelayOver={setIsSplashDelayOver}>
      <ConversationPanelWrapper
        isSplashDelayOver={isSplashDelayOver}
        splashScreen={splashScreen}
      />
    </SdkConnectionWrapper>
  ) : null;
});
