import Bugsnag from '@bugsnag/js';

import { BugsnagReporter } from '@service-initiation/shared-clients-bugsnag-reporter';
import {
  Logger,
  consoleReporter,
} from '@service-initiation/shared-clients-logger';

import { withUserBrowserContext } from './transformations';

export const logger = new Logger({
  reporters:
    process.env['NX_PUBLIC_APP_ENV'] === 'production'
      ? [
          new BugsnagReporter({
            client: Bugsnag.start({
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              apiKey: process.env['NX_PUBLIC_BUGSNAG_API_KEY']!,
              appVersion: `web-${process.env['NX_PUBLIC_APP_NAME']}-${process.env['GIT_REVISION_HASH']}`,
            }),
            reportableSeverities: ['error', 'warn'],
          }),
        ]
      : [consoleReporter],
  transformations: [withUserBrowserContext],
});
