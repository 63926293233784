import {
  type HttpClientRequestOptions,
  type HttpClientResponse,
} from '@service-initiation/shared-clients-http';

import { fetchResponseToHttpClientResponse } from './fetchResponseToHttpClientResponse';
import { isApplicationJson } from './isApplicationJson';

export type HttpFetchParams<Request> = {
  body: Request;
  method: string;
  options?: HttpClientRequestOptions;
  url: string;
};

export const fetchHttp = async <TRequest, TResponse>({
  body,
  method,
  options,
  url,
}: HttpFetchParams<TRequest>): Promise<HttpClientResponse<TResponse>> => {
  const contentType = options?.headers?.['Content-Type'] || 'application/json';
  const response = await fetch(url, {
    ...options,
    ...(body
      ? isApplicationJson(contentType)
        ? { body: JSON.stringify(body) }
        : { body: body as BodyInit | null | undefined }
      : {}),
    headers: {
      ...(body ? { 'Content-Type': contentType } : {}),
      ...options?.headers,
    },
    method,
  });

  return await fetchResponseToHttpClientResponse<TResponse>(response);
};
