import {
  DIRECTIONS,
  type TMessage,
  getMessageDirection,
} from '@simplrsupport/headless-chat-web';

import { SenderOptions } from '@service-initiation/widget-ui-chat-bubble';

export const getSenderFromDirection = (message: TMessage) => {
  const direction = getMessageDirection(message);
  return direction === DIRECTIONS.INBOUND
    ? SenderOptions.USER
    : SenderOptions.ORIGIN;
};
