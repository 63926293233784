import { format, fromUnixTime } from 'date-fns';

import { type CheckWidgetStatusResponse } from '@service-initiation/shared-clients-mindful';

import { type MindfulClientResponse, type MindfulDateTimeMap } from '../types';

export const convertMindfulServerToClientResponse = (
  payload: CheckWidgetStatusResponse
): MindfulClientResponse => {
  const ewt = payload.ewt / 10;

  const dayAndTimeMap = payload.scheduled_timeslots.reduce(
    (acc: MindfulDateTimeMap, timestamp: string) => {
      const dateObj = fromUnixTime(Number(timestamp));

      const date = format(dateObj, 'd');
      const month = format(dateObj, 'MMMM');
      const weekday = format(dateObj, 'EEEE');
      const time = format(dateObj, 'h:mm a');

      const dateKey = format(dateObj, 'MMddyyyy');

      if (!acc[dateKey]) {
        acc[dateKey] = {
          date,
          month,
          times: [],
          value: dateKey,
          weekday,
        };
      }

      acc[dateKey].times.push({ name: time, value: timestamp });

      return acc;
    },
    {} as MindfulDateTimeMap
  );

  return {
    dayAndTimeMap,
    ewt,
  };
};
