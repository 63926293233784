import { useAsyncFn } from 'react-use';

import { type CreateCallbackClientRequest } from '@service-initiation/shared-clients-widget-services';

import { createCallback } from '../createCallback';

export const useCreateMindfulCallback = () =>
  useAsyncFn(async (payload: CreateCallbackClientRequest) => {
    return await createCallback(payload);
  });
