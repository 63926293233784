import { isApplicationJson } from './isApplicationJson';

export const convertBody = async (response: Response) => {
  const contentType = response.headers.get('Content-Type');

  try {
    if (!contentType) {
      return;
    } else if (isApplicationJson(contentType)) {
      return await response.json();
    } else {
      return await response.text();
    }
  } catch (_e) {
    return;
  }
};
