import styled from 'styled-components';

import { Icon, Text, colors } from '@soluto-private/mx-asurion-ui-react';

const TextContainer = styled.div`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CollapsedHeaderContent = () => {
  return (
    <>
      <TextContainer>
        <Text as="h1" size={2} weight="heavy">
          Get Pixel support
        </Text>
      </TextContainer>
      <Icon
        fill={colors.white}
        size="large"
        src="chat-bubble-circle"
        title="asurion"
      />
      <Icon fill={colors.white} size="large" src="phone" title="asurion" />
    </>
  );
};
