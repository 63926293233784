import styled from 'styled-components';

import { Skeleton } from '@soluto-private/mx-asurion-ui-react';

const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 2em;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Headline = styled(Container)`
  gap: 8px;
  & > *:last-child {
    padding: 0 12%;
  }
`;

const Content = styled(Container)`
  gap: 16px;
`;

export const StepContentSkeleton = () => {
  return (
    <SkeletonContainer
      aria-label="loading"
      data-testid="step-skeleton-loader"
      role="status"
    >
      <Headline>
        <Skeleton height="56px" />
        <Skeleton height="40px" />
      </Headline>
      <Content>
        <Skeleton height="156px" />
        <Skeleton height="156px" />
      </Content>
    </SkeletonContainer>
  );
};
