import { animated, useTransition } from '@react-spring/web';

import { type SenderOptions } from '@service-initiation/widget-ui-chat-bubble';

const isSenderDifferentThanLast = (
  messageComponent: JSX.Element,
  previousMessageComponents: JSX.Element[]
) => {
  const mainSender = messageComponent.props['sender'] as
    | SenderOptions
    | undefined;
  if (previousMessageComponents.length > 0) {
    const previousSender = previousMessageComponents[
      previousMessageComponents.length - 1
    ].props['sender'] as SenderOptions | undefined;
    if (mainSender !== previousSender) {
      return true;
    }
  }
  return false;
};

export const useFlattenAndComposeAnimatedComponentList = (
  componentMap: Map<JSX.Element, JSX.Element[]>
) => {
  const components = Array.from(componentMap.entries()).reduce(
    (acc, [messageComponent, quickInputComponents]) => {
      if (isSenderDifferentThanLast(messageComponent, acc)) {
        messageComponent = {
          ...messageComponent,
          props: {
            ...messageComponent.props,
            isDifferentSenderFromLast: true,
          },
        };
      }
      acc.push(messageComponent);
      quickInputComponents.forEach((quickInputComponent) => {
        if (isSenderDifferentThanLast(quickInputComponent, acc)) {
          quickInputComponent = {
            ...quickInputComponent,
            props: {
              ...quickInputComponent.props,
              isDifferentSenderFromLast: true,
            },
          };
        }
        acc.push(quickInputComponent);
      });
      return acc;
    },
    [] as JSX.Element[]
  );

  const componentsWithSequentialKeys = components.map((component, index) => {
    return { ...component, key: index };
  });

  const transitions = useTransition(componentsWithSequentialKeys, {
    config: { bounce: 1, duration: 200 },
    enter: { opacity: 1, transform: 'scale(1)' },
    from: { opacity: 0, transform: 'scale(0)' },
    keys: (item) => item.key,
    trail: 200,
  });

  const animatedComponentList = transitions((style, item) => {
    return <animated.div style={style}>{item}</animated.div>;
  });

  return animatedComponentList;
};
