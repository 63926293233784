import styled from 'styled-components';

import { Action, Icon, Text } from '@soluto-private/mx-asurion-ui-react';

import { useGetInitialChatSessionMessage } from '../hooks';

const TopicHeaderContainer = styled.div`
  border-bottom: 1.05px solid var(--surfaces-outline, #c6c6ca);
`;

const TopicHeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  margin: 0 16px;
`;

const ActionWrapper = styled.div`
  flex-basis: 1;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TopicIconWrapper = styled.div`
  flex-basis: 1;
  min-width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TextWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
`;

type TopicHeaderProps = {
  onBackClick: () => void;
};

export const TopicHeader = ({ onBackClick }: TopicHeaderProps) => {
  const initialMessage = useGetInitialChatSessionMessage();

  return (
    <TopicHeaderContainer>
      <TopicHeaderContent>
        <ActionWrapper>
          <Action
            onClick={() => onBackClick()}
            size="sm"
            state="enabled"
            variant="flat"
          >
            <Icon ariaLabel="Go back" size="large" src="chevron-left" />
          </Action>
        </ActionWrapper>
        {initialMessage && (
          <>
            <TextWrapper>
              <Text>{initialMessage.text}</Text>
            </TextWrapper>
            {initialMessage.image?.src && (
              <TopicIconWrapper>
                <Icon
                  ariaLabel={`${initialMessage.text} icon`}
                  size="large"
                  src={initialMessage.image?.src}
                />
              </TopicIconWrapper>
            )}
          </>
        )}
      </TopicHeaderContent>
    </TopicHeaderContainer>
  );
};
