import styled from 'styled-components';

import { Modal } from '@soluto-private/aui-react-modal';
import { Text } from '@soluto-private/mx-asurion-ui-react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { useBumperContext } from '@service-initiation/widget-features-bumper';
import {
  FlatButton,
  OutlineButton,
  SolidButton,
} from '@service-initiation/widget-google-ui-google-buttons';

const StyledModal = styled(Modal)`
  margin: 2.5em;
`;

export type ExitModalProps = {
  containerRef: React.RefObject<HTMLDivElement>;
  handleCloseModal: () => void;
  handleLeaveConversation: () => void;
  handleSaveConversation: () => void;
  isExitModalOpen: boolean;
};
export const ExitModal = ({
  containerRef,
  handleCloseModal,
  handleLeaveConversation,
  handleSaveConversation,
  isExitModalOpen,
}: ExitModalProps) => {
  const { theme } = useBumperContext();
  return (
    <StyledModal
      ariaLabel="modal"
      isOpen={isExitModalOpen}
      onCloseModal={() => handleCloseModal()}
      portalContainer={containerRef.current ?? undefined}
    >
      <Modal.Header>
        <Modal.Title>Are you sure you want to go?</Modal.Title>
      </Modal.Header>
      <Modal.Content>
        <Text>
          Leaving will delete this session. Start a new session at any time.
        </Text>
      </Modal.Content>
      <Modal.Footer>
        <SolidButton buttonTheme={theme} onClick={() => handleCloseModal()}>
          Stay
        </SolidButton>
        <OutlineButton
          buttonTheme={theme}
          onClick={() => handleLeaveConversation()}
          variant="outline"
        >
          Leave
        </OutlineButton>
        <FlatButton onClick={() => handleSaveConversation()} variant="flat">
          Save this conversation
        </FlatButton>
      </Modal.Footer>
    </StyledModal>
  );
};
