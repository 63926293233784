import { UserBrowser } from '@soluto-private/mx-user-browser';

import {
  type HttpError,
  HttpHeader,
} from '@service-initiation/shared-clients-http';
import { queryClient } from '@service-initiation/shared-utils-query-client';
import { logger } from '@service-initiation/widget-clients-logger';
import { widgetServicesClient } from '@service-initiation/widget-clients-widget-services';

import { type MindfulClientResponse } from './types';
import { convertMindfulServerToClientResponse } from './utils';
export const getWidgetStatus = async (): Promise<MindfulClientResponse> => {
  try {
    const res = await queryClient.fetchQuery({
      queryFn: async () => {
        const res = await widgetServicesClient.getWidgetStatusRequest({
          headers: {
            [HttpHeader.AsurionCorrelationId]: UserBrowser.SessionId,
          },
        });

        return res;
      },
      queryKey: ['getWidgetStatus'],
      retry: 2,
      staleTime: Infinity,
    });
    return convertMindfulServerToClientResponse(res.body);
  } catch (e) {
    logger.error('Error occurred while trying to fetch widget status.', {
      errorMessage: (e as HttpError).message,
      statusCode: (e as HttpError).statusCode,
    });
    throw e;
  }
};
