import {
  MESSAGE_DATA_TYPES,
  SimplrChatSDK,
  type TTextMessageData,
  useChatSessionStateStore,
  useMessagesStore,
} from '@simplrsupport/headless-chat-web';
import { useCallback } from 'react';

type InitializeChatProps = {
  auiIcon?: string;
  text: string;
};

const getInitialMessage = ({ auiIcon, text }: Partial<InitializeChatProps>) => {
  if (!text) {
    return;
  }

  return {
    displayText: text,
    image: {
      alt: 'icon',
      src: auiIcon,
    },
    meta: {
      csdId: 'customer_message',
      csdPrefix: 'Customer Message',
      isInitialMessage: true,
    },
    text: text,
    type: MESSAGE_DATA_TYPES.TEXT,
  } as TTextMessageData;
};

export const useChatInitialization = () => {
  const clearChatHistory = useMessagesStore((s) => s.clearChatHistory);
  const messagesExist = useMessagesStore((s) => !!s.messages.length);
  const setInitialMessage = useChatSessionStateStore(
    (s) => s.setInitialMessage
  );

  const initializeChat = useCallback(
    ({ auiIcon, text }: InitializeChatProps) => {
      clearChatHistory();
      const initMsg = getInitialMessage({ auiIcon, text });
      setInitialMessage(initMsg || null);

      if (messagesExist) {
        SimplrChatSDK.startOver(initMsg);
      }
    },
    [clearChatHistory, messagesExist, setInitialMessage]
  );

  return { initializeChat };
};
