import { useCallback, useEffect } from 'react';

import { bumperToHostWindowConnection } from '@service-initiation/widget-clients-bumper-to-host-window-connection';
import { useChatInitialization } from '@service-initiation/widget-features-simplr-chat';
import { type InitiateSimplrChatMessagePayload } from '@service-initiation/widget-loader-clients-bumper-widget-window-connection';

export const useSimplrChatActionListener = () => {
  const { initializeChat } = useChatInitialization();

  const handleInitiateChat = useCallback(
    (payload?: InitiateSimplrChatMessagePayload) => {
      if (!payload) return;

      initializeChat({ text: payload.message });
    },
    [initializeChat]
  );

  useEffect(() => {
    bumperToHostWindowConnection.addListener(
      'simplr-initiate-chat',
      handleInitiateChat
    );

    return () => {
      bumperToHostWindowConnection.removeListener(
        'simplr-initiate-chat',
        handleInitiateChat
      );
    };
  }, [handleInitiateChat]);
};
