import {
  MESSAGE_DATA_TYPES,
  QUICK_INPUT_TYPES,
  type QuickInput,
  type QuickInputOption,
  SimplrChatSDK,
  type TTextMessageData,
  getMessageDataType,
  getMessageQuickInput,
  useMessagesStore,
} from '@simplrsupport/headless-chat-web';
import { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { useAnalytics } from '@service-initiation/shared-data-access-analytics';
import { WidgetEventId } from '@service-initiation/widget-data-access-analytics';
import { useSetDispatchSubPageAtom } from '@service-initiation/widget-data-access-storage';
import {
  ChatBubble,
  SenderOptions,
  VariantOptions,
} from '@service-initiation/widget-ui-chat-bubble';
import { ServiceButtonCard } from '@service-initiation/widget-ui-service-button-card';

import { getSenderFromDirection } from '../utils';
import { determineLatestMessage } from '../utils/determineLatestMessage';
import { useFlattenAndComposeAnimatedComponentList } from './useFlattenAndComposeAnimatedComponentList';

const ChatRow = styled.div<{
  isDifferentSenderFromLast?: boolean;
  sender: string;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: ${({ sender }) =>
    sender === SenderOptions.ORIGIN ? 'flex-start' : 'flex-end'};
  margin: 4px 0px;

  ${({ isDifferentSenderFromLast }) =>
    isDifferentSenderFromLast &&
    css`
      margin-top: 16px;
    `}
`;

enum OptionDataType {
  CALL = 'CALL',
  LINK = 'LINK',
  SIDE_PANEL = 'SIDE_PANEL',
}

export enum CALLBACK_TIME {
  ASAP = 'asap',
  SCHEDULE = 'schedule',
}

type OptionType = QuickInputOption & {
  data?: {
    analyticsId?: string;
    callbackType?: 'asap' | 'schedule';
    csdPrefix?: string;
    href?: string;
    type: OptionDataType;
  };
};

const FallbackComponent = () => null;

const MESSAGE_COMPONENT_MAPPER = {
  [MESSAGE_DATA_TYPES.CUSTOM]: ChatBubble,
  [MESSAGE_DATA_TYPES.TEXT]: ChatBubble,
};

const QUICK_REPLIES_MAPPER = {
  [QUICK_INPUT_TYPES.QUICK_REPLIES]: ServiceButtonCard,
};

export const useMessageComponentFactory = () => {
  const [messages, messagesState] = useMessagesStore((s) => [s.messages, s]);

  const messagesWithChildComponentsMap: Map<JSX.Element, JSX.Element[]> =
    new Map();

  const { setDispatchSubPageAtom } = useSetDispatchSubPageAtom();
  const { dispatchClickEvent } = useAnalytics();
  const handleQuickInputs = useCallback(
    (message: QuickInput) =>
      message.options.map((option, idx) => {
        const Component =
          message.type in QUICK_REPLIES_MAPPER
            ? QUICK_REPLIES_MAPPER[
                message.type as keyof typeof QUICK_REPLIES_MAPPER
              ]
            : FallbackComponent;
        const QuickInputOption = option as OptionType;
        const extraProps = {
          content: option.label,
          data: QuickInputOption.data,
          'data-analytics-id': option.value,
        };
        return (
          <ChatRow key={idx} sender={SenderOptions.USER}>
            <Component
              iconSrc={option.icon}
              key={idx}
              onClick={() => {
                dispatchClickEvent(
                  WidgetEventId.ChatWindow_QuickAction,
                  extraProps
                );
                if (QuickInputOption.data?.type === OptionDataType.SIDE_PANEL) {
                  setDispatchSubPageAtom({
                    pageData: {
                      callbackType: QuickInputOption.data.callbackType,
                      type: QuickInputOption.data.type,
                    },
                  });
                  return;
                } else if (
                  QuickInputOption.data?.type === OptionDataType.CALL
                ) {
                  window.open(`tel:${QuickInputOption.data.href}`);
                  return;
                } else if (
                  QuickInputOption.data?.type === OptionDataType.LINK
                ) {
                  window.open(QuickInputOption.data.href, '_blank');
                  return;
                }
                SimplrChatSDK.sendMessage({
                  displayText: option.value,
                  meta: {
                    csdId: QuickInputOption.data?.analyticsId,
                    csdPrefix: QuickInputOption.data?.csdPrefix,
                  },
                  text: option.label,
                  type: MESSAGE_DATA_TYPES.TEXT,
                } as TTextMessageData);
              }}
            >
              {option.label}
            </Component>
          </ChatRow>
        );
      }),
    [setDispatchSubPageAtom, dispatchClickEvent]
  );

  messages.forEach((message, idx) => {
    const messageType = getMessageDataType(message);
    const quickInput = getMessageQuickInput(message) as QuickInput | undefined;
    const isLatestMessage = determineLatestMessage(messagesState, message);
    const QuickInputComponents =
      quickInput && isLatestMessage ? handleQuickInputs(quickInput) : undefined;

    const sender = getSenderFromDirection(message);

    const Component =
      messageType in MESSAGE_COMPONENT_MAPPER
        ? MESSAGE_COMPONENT_MAPPER[
            messageType as keyof typeof MESSAGE_COMPONENT_MAPPER
          ]
        : FallbackComponent;

    const WrappedComponent = (
      <ChatRow sender={sender}>
        <Component
          isSameSenderAsNext={
            (QuickInputComponents || []).length === 0 &&
            idx + 1 < messages.length &&
            sender === getSenderFromDirection(messages[idx + 1])
          }
          message={message.data?.text}
          sender={getSenderFromDirection(message)}
          variant={
            QuickInputComponents ? VariantOptions.FLAT : VariantOptions.ROUND
          }
        />
      </ChatRow>
    );

    const SelectionComponents = QuickInputComponents ?? [];
    messagesWithChildComponentsMap.set(WrappedComponent, SelectionComponents);
  });

  const animatedComponents = useFlattenAndComposeAnimatedComponentList(
    messagesWithChildComponentsMap
  );

  return { componentList: animatedComponents };
};
