import { type LoggerReport, type LoggerReporter } from './types';

export const consoleReporter: LoggerReporter = {
  process: (report: LoggerReport) => {
    console[report.severity](report.message, {
      context: report.context,
      metadata: report.metadata,
    });
  },
};
