import {
  SimplrChatSDK,
  handleDownloadTranscript,
  useMessagesStore,
} from '@simplrsupport/headless-chat-web';
import styled from 'styled-components';

import { Text } from '@soluto-private/mx-asurion-ui-react';

import { useSetPopupAtom } from '@service-initiation/widget-data-access-storage';
import { useBumperContext } from '@service-initiation/widget-features-bumper';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  FlatButton,
  OutlineButton,
  SolidButton,
} from '@service-initiation/widget-google-ui-google-buttons';

import { useTerminateChat } from '../hooks';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 6em 2em 2em 2em;
  gap: 0.5em;
`;
export const SessionTimeout = () => {
  const setPopupAtom = useSetPopupAtom();
  const { theme } = useBumperContext();
  const terminateChatSession = useTerminateChat();
  const messageHistory = useMessagesStore((s) => s.messages);

  const handleStartNewChatClick = async () => {
    await SimplrChatSDK.startNewChatAfterTimeout();
    setPopupAtom(undefined);
  };

  return (
    <Container>
      <Text as="h1" size={4}>
        This session has ended due to inactivity
      </Text>
      <Text>Start a new session at any time.</Text>
      <SolidButton
        buttonTheme={theme}
        onClick={() => handleStartNewChatClick()}
      >
        Start a new chat
      </SolidButton>
      <OutlineButton
        buttonTheme={theme}
        onClick={() => terminateChatSession()}
        variant="outline"
      >
        Leave
      </OutlineButton>
      <FlatButton
        onClick={() => handleDownloadTranscript(messageHistory)}
        variant="flat"
      >
        Save this conversation
      </FlatButton>
    </Container>
  );
};
