import {
  type HttpClient,
  type HttpClientRequestOptions,
  type HttpClientResponse,
} from '@service-initiation/shared-clients-http';

import { fetchHttp } from '../utils/fetchHttp';

export const fetchHttpClient: HttpClient = {
  delete: async <Response>(
    url: string,
    options?: HttpClientRequestOptions
  ): Promise<HttpClientResponse<Response>> => {
    return await fetchHttp({
      body: undefined,
      method: 'DELETE',
      options,
      url,
    });
  },
  get: async <Response>(
    url: string,
    options?: HttpClientRequestOptions
  ): Promise<HttpClientResponse<Response>> => {
    return await fetchHttp({
      body: undefined,
      method: 'GET',
      options,
      url,
    });
  },
  patch: async <Request, Response>(
    url: string,
    body: Request,
    options?: HttpClientRequestOptions
  ): Promise<HttpClientResponse<Response>> => {
    return await fetchHttp({
      body,
      method: 'PATCH',
      options,
      url,
    });
  },
  post: async <Request, Response>(
    url: string,
    body: Request,
    options?: HttpClientRequestOptions
  ): Promise<HttpClientResponse<Response>> => {
    return await fetchHttp({
      body,
      method: 'POST',
      options,
      url,
    });
  },
  put: async <Request, Response>(
    url: string,
    body: Request,
    options?: HttpClientRequestOptions
  ): Promise<HttpClientResponse<Response>> => {
    return await fetchHttp({
      body,
      method: 'PUT',
      options,
      url,
    });
  },
};
