import styled from 'styled-components';

import { Action, colors } from '@soluto-private/mx-asurion-ui-react';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { type BumperTheme } from '@service-initiation/widget-features-bumper';

export const SolidButton = styled(Action)<{ buttonTheme: BumperTheme }>`
  background-color: ${({ buttonTheme }) => buttonTheme.button?.colors.primary};
  --aui-action-border-radius: 4px;
  width: 100%;
  border-radius: 4px;
  &:hover {
    && {
      border-radius: 4px;
      background-color: ${({ buttonTheme }) => buttonTheme.colors.primaryDeep};
      background-color: ${({ buttonTheme }) => buttonTheme.colors.primaryDeep};
    }
  }
`;

export const OutlineButton = styled(Action)<{ buttonTheme: BumperTheme }>`
  border-radius: 4px;
  --aui-action-border-radius: 4px;
  &:hover {
    && {
      border-radius: 4px;
      border-color: ${colors.white};
      background: ${colors.white};
      background-color: ${colors.white};
    }
  }
`;

export const FlatButton = styled(Action)`
  &:hover {
    && {
      color: unset;
    }
  }
`;
