import {
  type HttpClient,
  type HttpClientRequestOptions,
} from '@service-initiation/shared-clients-http';
import { type CheckWidgetStatusResponse } from '@service-initiation/shared-clients-mindful';
import { buildUrl } from '@service-initiation/shared-utils-helpers';

import { type CreateCallbackClientRequest } from './types/CreateCallbackClientRequest';

export type WidgetServicesClientOptions = {
  baseUrl?: string;
  httpClient: HttpClient;
};

export class WidgetServicesClient {
  private readonly baseUrl: WidgetServicesClientOptions['baseUrl'];
  private readonly httpClient: WidgetServicesClientOptions['httpClient'];

  constructor({
    baseUrl = process.env['NX_PUBLIC_WIDGET_SERVICES_BASE_URL'],
    httpClient,
  }: WidgetServicesClientOptions) {
    this.baseUrl = baseUrl;
    this.httpClient = httpClient;
  }

  public async createCallbackRequest(
    request: CreateCallbackClientRequest,
    options?: HttpClientRequestOptions
  ) {
    const url = buildUrl({
      baseUrl: this.baseUrl,
      path: '/callback',
    });
    return await this.httpClient.post<CreateCallbackClientRequest, string>(
      url,
      request,
      {
        headers: {
          ...options?.headers,
        },
      }
    );
  }

  public async getWidgetStatusRequest(options?: HttpClientRequestOptions) {
    const url = buildUrl({
      baseUrl: this.baseUrl,
      path: '/status',
    });
    return await this.httpClient.get<CheckWidgetStatusResponse>(url, {
      headers: {
        ...options?.headers,
      },
    });
  }
}
