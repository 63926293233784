import { type BumperTheme } from '@service-initiation/widget-features-bumper';

export const googleTheme: BumperTheme = {
  button: {
    colors: {
      primary: '#1967D2',
      primaryDeep: '#165cb9',
    },
  },
  colors: {
    primary: '#1967D2',
    primaryDeep: '#165cb9',
    primaryDeeper: '#0f4a8a',
  },
};
