import React from 'react';
import styled from 'styled-components';

import { useChildrenArray } from './hooks';

const AnimationParent = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`;

const ScrollableContainer = styled.div<{ activeIndex: number }>`
  display: flex;
  flex-direction: row;
  height: 100%;
  transition: transform 0.5s ease-out;
  transform: ${({ activeIndex }) => `translateX(-${activeIndex * 100}%)`};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
`;

interface AnimatedPanelStackProps {
  active: string;
  children: JSX.Element[];
}

export const AnimatedPanelStack = ({
  active,
  children,
}: AnimatedPanelStackProps) => {
  const contents = useChildrenArray<{
    children: React.ReactNode;
    id: string;
  }>(children);

  const activeIndex =
    contents?.findIndex((content) => content.id === active) || 0;

  return (
    <AnimationParent>
      <ScrollableContainer activeIndex={activeIndex}>
        {contents?.map((content) => (
          <Content
            key={content.id}
            style={{
              visibility: content.id === active ? 'visible' : 'hidden',
            }}
          >
            {content.children}
          </Content>
        ))}
      </ScrollableContainer>
    </AnimationParent>
  );
};

const Panel = ({ children, id }: { children: JSX.Element; id: string }) => {
  return children;
};

AnimatedPanelStack.Panel = Panel;

export default AnimatedPanelStack;
