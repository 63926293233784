import { memo, useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Bumper } from '@service-initiation/widget-features-bumper';

import { useMessageComponentFactory } from '../hooks';

const ConversationPanelWrapper = styled(Bumper.Panel)`
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

export const ConversationPanel = memo(() => {
  const panelRef = useRef<HTMLDivElement>(null);

  const { componentList } = useMessageComponentFactory();

  useEffect(() => {
    const scrollToBottomOfConversationPanel = () => {
      if (panelRef.current) {
        const scrollHeight = panelRef.current.scrollHeight;

        setTimeout(() => {
          panelRef.current?.scrollTo({
            behavior: 'smooth',
            top: scrollHeight,
          });
        }, 50);
      }
    };
    scrollToBottomOfConversationPanel();
  }, [componentList]);

  return (
    <ConversationPanelWrapper ref={panelRef}>
      {componentList}
    </ConversationPanelWrapper>
  );
});
